import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

import * as Color from "../../../utils/color"

const Detail = styled.p`
	${tw`text-base md:text-lg`}
	width: 60%;
`

const Header = styled.h2`
	${tw`text-5xl md:text-6xl`}
	font-weight: bold;
	color: ${Color.primary};
`

const Info = styled.div`
	${tw`mb-8`}
	color: ${Color.primary};

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
`

const Intro = styled.section`
	padding: 2em 0;
`

const Section = () => {
	return (
		<Intro>
			<Info>
				<Header>Solutions</Header>
				<Detail>
					Our Two Main Focuses
				</Detail>
			</Info>
		</Intro>
	)
}

export default Section
