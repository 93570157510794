import React from "react"
import { navigate } from "gatsby"
import styled from "@emotion/styled"

import SolutionItem from "../../../components/SolutionItem"
import Inner from "../../../components/Inner"

import { tablet, desktop } from "../../../utils/breakpoint"

import { solutions } from "../../../meta/solutions"

const List = styled.div`
	display: grid;
	grid-gap: 32px;

	@media ${tablet} {
		grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
	}

	@media ${desktop} {
		width: min-content;
		justify-self: center;
		grid-auto-columns: 400px;
		grid-template-areas: "a b";
	}
`

const Content = styled.div`
	display: grid;
	padding: 2em 0;
`

const Solutions = styled.section``

const Section = () => {
	return (
		<Solutions>
			<Inner>
				<Content>
					<List>
						{solutions.map((item, index) => {
							return (
								<SolutionItem
									key={index}
									title={item.title}
									detail={item.description}
									icon={item.icon}
									navigate={() => navigate(item.path)}
								/>
							)
						})}
					</List>
				</Content>
			</Inner>
		</Solutions>
	)
}

export default Section
