import React from "react"
import Intro from "../sections/solutions/intro"
import Solutions from "../sections/solutions/list"

const Wrapper = () => {
	return (
		<>
			<Intro />
			<Solutions />
		</>
	)
}

export default Wrapper
