import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

import Button from "../Button"

import * as Color from "../../utils/color"

const ButtonContainer = styled.div`
	display: inherit;
	${Button} {
		width: 180px;
		height: 50px;
		justify-self: center;
	}
`

const Detail = styled.div`
	${tw`text-base mb-8`}
	text-align: center;
	color: ${Color.primary};
`

const Title = styled.div`
	${tw`text-2xl leading-tight mb-3`}
	font-weight: bold;
	text-align: center;

	color: ${Color.primary};
`

const Icon = styled.div`
	${tw`mb-4`}

	width: 60px;
	height: 60px;
	background-color: ${Color.primary_200};
	border-radius: 50%;

	display: inherit;
	justify-items: center;
	align-items: center;

	justify-self: center;
	align-self: flex-end;

	> svg {
		fill: ${Color.primary};
	}
`

const Solution = styled.div`
	padding: 1.6em;
	display: grid;
	grid-template-rows: min-content min-content auto min-content;

	border-radius: 8px;
	border: solid 1px lightgray;
	background-color: white;
`

const SolutionItem = ({ icon, title, detail, navigate }) => {
	return (
		<Solution>
			<Icon>{icon}</Icon>
			<Title>{title}</Title>
			<Detail>{detail}</Detail>
			<ButtonContainer>
				<Button outline onClick={navigate} aria-label={"view projects"}>
					View Projects
				</Button>
			</ButtonContainer>
		</Solution>
	)
}

export default SolutionItem
